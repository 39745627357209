import { useEffect, useState } from 'react';
import { Routes, Route, useLocation, HashRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { initNear } from './store/near';

import routes from './pages/index'
import { initNearContracts } from './wallets/near';

import './App.css';

function App() {
    const dispatch = useDispatch();        

    const location = useLocation();
    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransistionStage] = useState("fadeInStage");
    const noTransition = [];

    useEffect(() => {

        initNearContracts().then(({near, nftContract, nftContract2, nftContract3, marketContract, nearConfig, wallet}) => {
          dispatch(initNear({
            api: near,
            config: nearConfig, 
            wallet, 
            nftContract, 
            nftContract2,
            nftContract3,
            marketContract
          }));
        });
    
    }, [dispatch]);

    useEffect(() => {
      if (location !== displayLocation) {
        
        if(
          (location.pathname == '/' || noTransition.reduce((prev, curr) => { return location.pathname.startsWith(curr) || prev }, false)) && 
          (displayLocation.pathname == '/' || noTransition.reduce((prev, curr) => { return displayLocation.pathname.startsWith(curr) || prev }, false))
        ) {
          setDisplayLocation(location);
        } else {
          setTransistionStage("fadeOutStage");      
        }
      }
    }, [location]);

    return (
      <div
        className={`${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === "fadeOutStage") {
            setTransistionStage("fadeInStage");
            setDisplayLocation(location);
          }
        }}
      >
        <Routes location={displayLocation}>
          {
            routes.map((data,index) => (
                <Route onUpdate={() => window.scrollTo(0, 0)} exact={true} path={data.path} element={data.component} key={index} />
            ))
          }
        </Routes>
      </div>
    );
}

export default App;
