import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";

import { ReactComponent as NearType } from '../../assets/images/icon/near_type.svg'

import NEAR from '../../constants/near';
import { useSelector } from 'react-redux';

const CardModalTMMP = (props) => {    
    const nearWallet = useSelector(state => state.near.wallet);

    const login = () => {
        nearWallet?.requestSignIn(
            NEAR.NFT_CONTRACT_NAME,
           'Hearhub Comics'
        );
    }

    return (

        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>

            <div className="modal-body space-y-20 pd-40">
                <h3>Mint Random NFT</h3>
                <p className="text-center">Your NFT will be minted at random from 4 possible designs</p>
                
                <div className="price" style={{}}>                                                                        
                    <h5 style={{fontSize: 40, display: 'flex', alignItems: 'center', marginBottom: 13, justifyContent: 'center'}}>0.420 <NearType style={{display:'inline', fill: '#fff', height: 15, marginLeft: 10}} /></h5>
                    <p className="text-center">20% Royalty</p>
                </div>
                { nearWallet?.isSignedIn() && nearWallet.getAccountId() &&
                  <a onClick={props.onPurchase} className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Purchase NFT">Mint NFT</a>
                }
                { nearWallet?.isSignedIn() !== true &&
                  <a onClick={login} className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Connect Wallet">Connect Wallet</a>
                }
            </div>
        </Modal>

    );
};

export default CardModalTMMP;
