import React, { useState, Fragment, useEffect, useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import * as nearAPI from 'near-api-js';

import Countdown from "react-countdown";
import CardModalPFP from '../CardModalPFP';

import 'swiper/scss';
import "swiper/css/effect-fade";

import transBack from '../../../assets/images/pfp/trans-back.png'

import PFPBackimg1 from '../../../assets/images/pfp/Attribute_Background/1.png'
import PFPBackimg2 from '../../../assets/images/pfp/Attribute_Background/2.png'
import PFPBackimg3 from '../../../assets/images/pfp/Attribute_Background/3.png'
import PFPBackimg4 from '../../../assets/images/pfp/Attribute_Background/4.png'
import PFPBackimg5 from '../../../assets/images/pfp/Attribute_Background/5.png'
import PFPBackimg6 from '../../../assets/images/pfp/Attribute_Background/6.png'
import PFPBackimg7 from '../../../assets/images/pfp/Attribute_Background/7.png'
import PFPBackimg8 from '../../../assets/images/pfp/Attribute_Background/8.png'
import PFPBackimg9 from '../../../assets/images/pfp/Attribute_Background/9.png'
import PFPBackimg10 from '../../../assets/images/pfp/Attribute_Background/10.png'
import PFPBackimg11 from '../../../assets/images/pfp/Attribute_Background/11.png'
import PFPBackimg12 from '../../../assets/images/pfp/Attribute_Background/12.png'
import PFPBackimg13 from '../../../assets/images/pfp/Attribute_Background/13.png'
import PFPBackimg14 from '../../../assets/images/pfp/Attribute_Background/14.png'
import PFPBackimg15 from '../../../assets/images/pfp/Attribute_Background/15.png'
import PFPBackimg16 from '../../../assets/images/pfp/Attribute_Background/16.png'
import PFPBackimg17 from '../../../assets/images/pfp/Attribute_Background/17.png'
import PFPBackimg18 from '../../../assets/images/pfp/Attribute_Background/18.png'
import PFPBackimg19 from '../../../assets/images/pfp/Attribute_Background/19.png'
import PFPBackimg20 from '../../../assets/images/pfp/Attribute_Background/20.png'

import PFPBodyimg1 from '../../../assets/images/pfp/Attribute_Body/1.png'
import PFPBodyimg2 from '../../../assets/images/pfp/Attribute_Body/2.png'
import PFPBodyimg3 from '../../../assets/images/pfp/Attribute_Body/3.png'
import PFPBodyimg4 from '../../../assets/images/pfp/Attribute_Body/4.png'
import PFPBodyimg5 from '../../../assets/images/pfp/Attribute_Body/5.png'
import PFPBodyimg6 from '../../../assets/images/pfp/Attribute_Body/6.png'
import PFPBodyimg7 from '../../../assets/images/pfp/Attribute_Body/7.png'
import PFPBodyimg8 from '../../../assets/images/pfp/Attribute_Body/8.png'
import PFPBodyimg9 from '../../../assets/images/pfp/Attribute_Body/9.png'
import PFPBodyimg10 from '../../../assets/images/pfp/Attribute_Body/10.png'
import PFPBodyimg11 from '../../../assets/images/pfp/Attribute_Body/11.png'
import PFPBodyimg12 from '../../../assets/images/pfp/Attribute_Body/12.png'
import PFPBodyimg13 from '../../../assets/images/pfp/Attribute_Body/13.png'
import PFPBodyimg14 from '../../../assets/images/pfp/Attribute_Body/14.png'
import PFPBodyimg15 from '../../../assets/images/pfp/Attribute_Body/15.png'

import PFPHeadimg1 from '../../../assets/images/pfp/Attribute_Head/1.png'
import PFPHeadimg2 from '../../../assets/images/pfp/Attribute_Head/2.png'
import PFPHeadimg3 from '../../../assets/images/pfp/Attribute_Head/3.png'
import PFPHeadimg4 from '../../../assets/images/pfp/Attribute_Head/4.png'
import PFPHeadimg5 from '../../../assets/images/pfp/Attribute_Head/5.png'
import PFPHeadimg6 from '../../../assets/images/pfp/Attribute_Head/6.png'
import PFPHeadimg7 from '../../../assets/images/pfp/Attribute_Head/7.png'
import PFPHeadimg8 from '../../../assets/images/pfp/Attribute_Head/8.png'
import PFPHeadimg9 from '../../../assets/images/pfp/Attribute_Head/9.png'
import PFPHeadimg10 from '../../../assets/images/pfp/Attribute_Head/10.png'
import PFPHeadimg11 from '../../../assets/images/pfp/Attribute_Head/11.png'
import PFPHeadimg12 from '../../../assets/images/pfp/Attribute_Head/12.png'
import PFPHeadimg13 from '../../../assets/images/pfp/Attribute_Head/13.png'
import PFPHeadimg14 from '../../../assets/images/pfp/Attribute_Head/14.png'
import PFPHeadimg15 from '../../../assets/images/pfp/Attribute_Head/15.png'
import PFPHeadimg16 from '../../../assets/images/pfp/Attribute_Head/16.png'
import PFPHeadimg17 from '../../../assets/images/pfp/Attribute_Head/17.png'
import PFPHeadimg18 from '../../../assets/images/pfp/Attribute_Head/18.png'
import PFPHeadimg19 from '../../../assets/images/pfp/Attribute_Head/19.png'
import PFPHeadimg20 from '../../../assets/images/pfp/Attribute_Head/20.png'
import PFPHeadimg21 from '../../../assets/images/pfp/Attribute_Head/21.png'
import PFPHeadimg22 from '../../../assets/images/pfp/Attribute_Head/22.png'
import PFPHeadimg23 from '../../../assets/images/pfp/Attribute_Head/23.png'
import PFPHeadimg24 from '../../../assets/images/pfp/Attribute_Head/24.png'
import PFPHeadimg25 from '../../../assets/images/pfp/Attribute_Head/25.png'
import PFPHeadimg26 from '../../../assets/images/pfp/Attribute_Head/26.png'
import PFPHeadimg27 from '../../../assets/images/pfp/Attribute_Head/27.png'
import PFPHeadimg28 from '../../../assets/images/pfp/Attribute_Head/28.png'
import PFPHeadimg29 from '../../../assets/images/pfp/Attribute_Head/29.png'
import PFPHeadimg30 from '../../../assets/images/pfp/Attribute_Head/30.png'
import PFPHeadimg31 from '../../../assets/images/pfp/Attribute_Head/31.png'
import PFPHeadimg32 from '../../../assets/images/pfp/Attribute_Head/32.png'
import PFPHeadimg33 from '../../../assets/images/pfp/Attribute_Head/33.png'
import PFPHeadimg34 from '../../../assets/images/pfp/Attribute_Head/34.png'
import PFPHeadimg35 from '../../../assets/images/pfp/Attribute_Head/35.png'
import PFPHeadimg36 from '../../../assets/images/pfp/Attribute_Head/36.png'
import PFPHeadimg37 from '../../../assets/images/pfp/Attribute_Head/37.png'
import PFPHeadimg38 from '../../../assets/images/pfp/Attribute_Head/38.png'
import PFPHeadimg39 from '../../../assets/images/pfp/Attribute_Head/39.png'
import PFPHeadimg40 from '../../../assets/images/pfp/Attribute_Head/40.png'
import PFPHeadimg41 from '../../../assets/images/pfp/Attribute_Head/41.png'
import PFPHeadimg42 from '../../../assets/images/pfp/Attribute_Head/42.png'
import PFPHeadimg43 from '../../../assets/images/pfp/Attribute_Head/43.png'
import PFPHeadimg44 from '../../../assets/images/pfp/Attribute_Head/44.png'
import PFPHeadimg45 from '../../../assets/images/pfp/Attribute_Head/45.png'
import PFPHeadimg46 from '../../../assets/images/pfp/Attribute_Head/46.png'
import PFPHeadimg47 from '../../../assets/images/pfp/Attribute_Head/47.png'
import PFPHeadimg48 from '../../../assets/images/pfp/Attribute_Head/48.png'

import PFPHeaderimg1 from '../../../assets/images/pfp/Attribute_Header/1.png'
import PFPHeaderimg2 from '../../../assets/images/pfp/Attribute_Header/2.png'
import PFPHeaderimg3 from '../../../assets/images/pfp/Attribute_Header/3.png'
import PFPHeaderimg4 from '../../../assets/images/pfp/Attribute_Header/4.png'
import PFPHeaderimg5 from '../../../assets/images/pfp/Attribute_Header/5.png'
import PFPHeaderimg6 from '../../../assets/images/pfp/Attribute_Header/6.png'
import PFPHeaderimg7 from '../../../assets/images/pfp/Attribute_Header/7.png'
import PFPHeaderimg8 from '../../../assets/images/pfp/Attribute_Header/8.png'
import PFPHeaderimg9 from '../../../assets/images/pfp/Attribute_Header/9.png'
import PFPHeaderimg10 from '../../../assets/images/pfp/Attribute_Header/10.png'
import PFPHeaderimg11 from '../../../assets/images/pfp/Attribute_Header/11.png'
import PFPHeaderimg12 from '../../../assets/images/pfp/Attribute_Header/12.png'
import PFPHeaderimg13 from '../../../assets/images/pfp/Attribute_Header/13.png'
import PFPHeaderimg14 from '../../../assets/images/pfp/Attribute_Header/14.png'
import PFPHeaderimg15 from '../../../assets/images/pfp/Attribute_Header/15.png'
import PFPHeaderimg16 from '../../../assets/images/pfp/Attribute_Header/16.png'
import PFPHeaderimg17 from '../../../assets/images/pfp/Attribute_Header/17.png'
import PFPHeaderimg18 from '../../../assets/images/pfp/Attribute_Header/18.png'
import PFPHeaderimg19 from '../../../assets/images/pfp/Attribute_Header/19.png'
import PFPHeaderimg20 from '../../../assets/images/pfp/Attribute_Header/20.png'
import PFPHeaderimg21 from '../../../assets/images/pfp/Attribute_Header/21.png'
import PFPHeaderimg22 from '../../../assets/images/pfp/Attribute_Header/22.png'
import PFPHeaderimg23 from '../../../assets/images/pfp/Attribute_Header/22.png'
import PFPHeaderimg24 from '../../../assets/images/pfp/Attribute_Header/24.png'

import PFPLeftArmimg1 from '../../../assets/images/pfp/Attribute_LeftArm/1.png'
import PFPLeftArmimg2 from '../../../assets/images/pfp/Attribute_LeftArm/2.png'
import PFPLeftArmimg3 from '../../../assets/images/pfp/Attribute_LeftArm/3.png'
import PFPLeftArmimg4 from '../../../assets/images/pfp/Attribute_LeftArm/4.png'
import PFPLeftArmimg5 from '../../../assets/images/pfp/Attribute_LeftArm/5.png'

import PFPRightArmimg1 from '../../../assets/images/pfp/Attribute_RightArm/1.png'
import PFPRightArmimg2 from '../../../assets/images/pfp/Attribute_RightArm/2.png'
import PFPRightArmimg3 from '../../../assets/images/pfp/Attribute_RightArm/3.png'
import PFPRightArmimg4 from '../../../assets/images/pfp/Attribute_RightArm/4.png'
import PFPRightArmimg5 from '../../../assets/images/pfp/Attribute_RightArm/5.png'

import PFPTextBubbleimg1 from '../../../assets/images/pfp/\Attribute_Textbubble/1.png'
import PFPTextBubbleimg2 from '../../../assets/images/pfp/\Attribute_Textbubble/2.png'
import PFPTextBubbleimg3 from '../../../assets/images/pfp/\Attribute_Textbubble/3.png'
import PFPTextBubbleimg4 from '../../../assets/images/pfp/\Attribute_Textbubble/4.png'

import logo from '../../../assets/images/WL/logo.png'
import { ReactComponent as NearType } from '../../../assets/images/icon/near_type.svg'

import { useSelector } from 'react-redux';

const useQuery = () => {      
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const useInterval = (callback, delay) => {
    const savedCallback = useRef();
  
    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
  
      if (delay !== null) {
        let id = setInterval(tick, delay);
  
        //cleaning function set for the future (autoexecuted when "delay" change)
        return () => {
          clearInterval(id);
        };
      }
    }, [delay]);
  }

const LiveAuctionPFP = () => {

    const [data, setData] = useState({
        img: [
            [PFPBackimg1, PFPBackimg2, PFPBackimg3, PFPBackimg4, PFPBackimg5, PFPBackimg6, PFPBackimg7, PFPBackimg8, PFPBackimg9, PFPBackimg10,
                PFPBackimg11,PFPBackimg12,PFPBackimg13,PFPBackimg14,PFPBackimg15,PFPBackimg16,PFPBackimg17,PFPBackimg18,PFPBackimg19,PFPBackimg20],
            [PFPBodyimg1, PFPBodyimg2, PFPBodyimg3, PFPBodyimg4, PFPBodyimg5, PFPBodyimg6, PFPBodyimg7, PFPBodyimg8, PFPBodyimg9, PFPBodyimg10,
                PFPBodyimg11,PFPBodyimg12,PFPBodyimg13,PFPBodyimg14,PFPBodyimg15],
            [PFPHeadimg1, PFPHeadimg2, PFPHeadimg3, PFPHeadimg4, PFPHeadimg5, PFPHeadimg6, PFPHeadimg7, PFPHeadimg8, PFPHeadimg9, PFPHeadimg10, 
                PFPHeadimg11,PFPHeadimg12,PFPHeadimg13,PFPHeadimg14,PFPHeadimg15,PFPHeadimg16,PFPHeadimg17,PFPHeadimg18,PFPHeadimg19,PFPHeadimg20,
                PFPHeadimg21,PFPHeadimg22,PFPHeadimg23,PFPHeadimg24,PFPHeadimg25,PFPHeadimg26,PFPHeadimg27,PFPHeadimg28,PFPHeadimg29,PFPHeadimg30,
                PFPHeadimg31,PFPHeadimg32,PFPHeadimg33,PFPHeadimg34,PFPHeadimg35,PFPHeadimg36,PFPHeadimg37,PFPHeadimg38,PFPHeadimg39,PFPHeadimg40,
                PFPHeadimg41,PFPHeadimg42,PFPHeadimg43,PFPHeadimg44,PFPHeadimg45,PFPHeadimg46,PFPHeadimg47,PFPHeadimg48],
            [PFPHeaderimg1, PFPHeaderimg2, PFPHeaderimg3, PFPHeaderimg4, PFPHeaderimg5, PFPHeaderimg6, PFPHeaderimg7, PFPHeaderimg8, PFPHeaderimg9, PFPHeaderimg10, 
                PFPHeaderimg11,PFPHeaderimg12,PFPHeaderimg13,PFPHeaderimg14,PFPHeaderimg15,PFPHeaderimg16,PFPHeaderimg17,PFPHeaderimg18,PFPHeaderimg19,PFPHeaderimg20,
                PFPHeaderimg21,PFPHeaderimg22,PFPHeaderimg23,PFPHeaderimg24],
            [PFPLeftArmimg1,PFPLeftArmimg2,PFPLeftArmimg3,PFPLeftArmimg4,PFPLeftArmimg5],
            [PFPRightArmimg1,PFPRightArmimg2,PFPRightArmimg3,PFPRightArmimg4,PFPRightArmimg5],
            [PFPTextBubbleimg1,PFPTextBubbleimg2,PFPTextBubbleimg3,PFPTextBubbleimg4]
        ],
        selected: [
            Math.floor(Math.random() * 20) + 1,
            Math.floor(Math.random() * 15) + 1,
            Math.floor(Math.random() * 48) + 1,
            Math.floor(Math.random() * 24) + 1,
            Math.floor(Math.random() * 5) + 1,
            Math.floor(Math.random() * 5) + 1,
            Math.floor(Math.random() * 4) + 1
        ],
        prevSelected: [0,0,0,0,0,0,0]
    });

    const nearWallet = useSelector(state => state.near.wallet);
    const nearConfig = useSelector(state => state.near.config);
    const nftContract = useSelector(state => state.near.nftContract3);
    const [modalShow, setModalShow] = useState(false);
    const [slideTitle, setSlideTitle] = useState('HRMS');
    const [qtySold, setQtySold] = useState('-');
    const [tokens, setTokens] = useState([]);
    const { utils } = nearAPI;
    const query = useQuery();

    useEffect(() => {
        if (nftContract) {
            nftContract.get_user_minted_quantity().then((qty) => setQtySold(qty));
            if (nearWallet?.isSignedIn()) {
                nftContract.nft_tokens_for_owner({account_id: nearWallet.getAccountId()}).then((t) => setTokens(t));
            }
        }
    }, [nftContract, nearWallet]);

    useInterval(()=> {
        const newData = {...data}            
        newData.prevSelected = [...newData.selected];
        newData.selected = newData.selected.map((ele2, ind2) => Math.floor(Math.random() * data.img[ind2].length) + 1);            
        setData(newData);
    }, 2000);

    useEffect(() => {
        if (nearConfig && query && query.get('transactionHashes')) {         
            /*   
            const tx = query.get('transactionHashes');

            const { providers } = nearAPI;
            const provider = new providers.JsonRpcProvider(nearConfig.archivalUrl);
            provider.txStatus(tx, nearWallet.getAccountId()).then(r => {     
                console.log(r);           
                if (r?.status?.SuccessValue) {
                    const result = JSON.parse(atob(r.status.SuccessValue));
                    console.log('result', result);
                    if (result.success) {
                        
                    } 
                }
            });            
            */
        }
    }, [query, nearConfig]);

    const handlePurchase = async (receiver) => {
        const result = await nftContract.nft_mint(
            {},
            "75000000000000",
            utils.format.parseNearAmount("7") 
        );
    }    

    return (
        <Fragment>
            <section className="tf-section live-auctions">
                { tokens && tokens.length > 0 
                  && tokens.map((nft, ind) => {
                      return <div key={'nft'+ind} className="themesflat-container">
                        {
                            /*
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="heading-live-auctions">
                                    <h2 className="tf-title" style={{padding:0}}>{nft.metadata.title}</h2>   
                                    <span style={{fontSize:16}}>#{nft.token_id} of 500</span>                             
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                            */
                        }

                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="swiper-container show-shadow carousel auctions">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="slider-item">
                                                <div className="sc-card-product">
                                                    <div className="card-media">
                                                        
                                                            <img src={nft.metadata.media} alt={nft.metadata.title} />
                                                    </div>
                                                    <div className="card-title">
                                                        <h5><a>{nft.metadata.title}</a></h5>
                                                        
                                                    </div>
                                                    <div className="meta-info">
                                                        <div className="author">
                                                            <div className="info">
                                                                <span>Owner</span>
                                                                <h6><a to="/authors-02">{nft.owner_id}</a> </h6>
                                                            </div>
                                                        </div>
                                                        <div className="price" style={{}}>                                                                        
                                                            <h5 style={{fontSize:20, display:'flex', alignItems: 'center', marginRight: 10}}>
                                                                #{ nft.token_id } of 2100
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="col-md-2"></div>
                        </div>

                      </div>
                  })
                }
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="heading-live-auctions">
                                <h2 className="tf-title" style={{padding:0}}>PFP NFTs</h2>   
                                <span style={{fontSize:16}}>{2100-qtySold} of 2100 available</span>                             
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">                            

                            <div className="swiper-container show-shadow carousel auctions nbc-collections">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="slider-item">
                                            <div className="sc-card-product">

                                                { 
                                                    qtySold != 2100 &&
                                                    <div className="button-place-bid" style={{zIndex: 22}}>
                                                        <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Mint Random NFT</span></button>
                                                    </div>
                                                }

                                                <div className="card-media">
                                                        <a>
                                                            {
                                                                data.img.map((img, ind) => {                                                                
                                                                    return img.map((img2, ind2) => <img key={'img'+ind+ind2} 
                                                                    src={(data.selected[ind] == (ind2+1) || data.prevSelected[ind] == (ind2+1) || ind == 0) ? img2 : transBack} 
                                                                    alt={data.title} 
                                                                    className={data.selected[ind] == (ind2+1) ? 'img selected' : 'img'} />);
                                                                })
                                                            }  
                                                        </a>
                                                        { 
                                                            qtySold != 2100 &&
                                                            <div className="button-place-bid" style={{zIndex: 22}}>
                                                                <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Mint Random NFT</span></button>
                                                            </div>
                                                        }
                                                </div>

                                                { 
                                                    qtySold != 2100 &&
                                                    <div className="button-place-bid" style={{zIndex: 22}}>
                                                        <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Mint Random NFT</span></button>
                                                    </div>
                                                }

                                                <div className="card-title">
                                                    <h5><a>{slideTitle}</a></h5>
                                                    
                                                </div>
                                                <div className="meta-info">
                                                    <div className="author">
                                                        <div className="avatar" style={{borderRadius:0}}>
                                                            <img src={logo} alt="Axies" />
                                                        </div>
                                                        <div className="info">
                                                            <span>Creator</span>
                                                            <h6><a to="/authors-02">Nearhub Comics</a> </h6>
                                                        </div>
                                                    </div>
                                                    <div className="price" style={{}}>                                                                        
                                                        <h5 style={{fontSize:40, display:'flex', alignItems: 'center', marginTop: -36, marginRight: 10}}>
                                                            { 
                                                                qtySold == 2100 
                                                                ? 'SOLD OUT'
                                                                : <>7 <NearType style={{display:'inline', fill: '#fff', height: 15, marginLeft: 10}} /></>
                                                            }
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>                
            </section>
            <CardModalPFP
                show={modalShow}
                onHide={() => setModalShow(false)}
                onPurchase={handlePurchase}                
            />
        </Fragment>
    );
}


export default LiveAuctionPFP;
