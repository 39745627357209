import React from 'react';
import HeaderStyle2WL from '../components/header/HeaderStyle2WL';
import Footer from '../components/footer/Footer';
import SliderStyle2 from '../components/slider/SliderStyle2';
import heroSliderData from '../assets/fake-data/data-slider';
import BrowCategory from '../components/layouts/home-5/BrowCategory';
import LiveAuction from '../components/layouts/home-5/LiveAuction';
import LiveAuctionWL from '../components/layouts/home-5/LiveAuctionWL';
import LiveAuctionPFP from '../components/layouts/home-5/LiveAuctionPFP';
import TopSeller from '../components/layouts/home-5/TopSeller';
import TodayPicks from '../components/layouts/home-5/TodayPicks';
import todayPickData from '../assets/fake-data/data-today-pick';
import PopularCollection from '../components/layouts/home-5/PopularCollection';
import Create from '../components/layouts/home-2/Create';

import { Autoplay, EffectCoverflow, EffectFade, EffectCreative, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import "swiper/css/effect-fade";
import "swiper/css/effect-coverflow";
import 'swiper/scss/pagination';
import "swiper/css/effect-creative";
import "swiper/css/navigation";

import img1 from '../assets/images/WL/slide1.jpg'
import img2 from '../assets/images/WL/slide2.jpg'
import img3 from '../assets/images/WL/slide3.jpg'
import mimg1 from '../assets/images/WL/mslide1.jpg'
import mimg2 from '../assets/images/WL/mslide2.jpg'
import mimg3 from '../assets/images/WL/mslide3.jpg'

const Home = () => {
    return (
        <div className='home-5'>
            <HeaderStyle2WL />
            <SliderStyle2 data={heroSliderData} />
            <LiveAuction />            
            <Swiper
                effect={"fade"}
                navigation={true}
                modules={[EffectFade, Autoplay,Navigation]}                        
                loop
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                creativeEffect={{
                    prev: {
                      shadow: true,
                      origin: "left center",
                      translate: ["-5%", 0, -200],
                      rotate: [0, 100, 0],
                    },
                    next: {
                      origin: "right center",
                      translate: ["5%", 0, -200],
                      rotate: [0, -100, 0],
                    },
                }}
                speed={2000}
                className='desktopSwiper'
                style={{width: '100%', maxWidth:'1200px', marginTop: '40px', marginBottom: '40px'}}
            >
                <SwiperSlide><img src={img1} alt="NFT Whitelist Details" style={{width: '100%', maxWidth:'1200px'}} /></SwiperSlide>
                <SwiperSlide><img src={img2} alt="PFP NFT Drop Details" style={{width: '100%', maxWidth:'1200px'}} /></SwiperSlide>
                <SwiperSlide><img src={img3} alt="Comic Book Page Details" style={{width: '100%', maxWidth:'1200px'}} /></SwiperSlide>
            </Swiper>         
            <Swiper
                effect={"fade"}
                navigation={true}
                modules={[EffectFade, Autoplay,Navigation]}                        
                loop
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                creativeEffect={{
                    prev: {
                      shadow: true,
                      origin: "left center",
                      translate: ["-5%", 0, -200],
                      rotate: [0, 100, 0],
                    },
                    next: {
                      origin: "right center",
                      translate: ["5%", 0, -200],
                      rotate: [0, -100, 0],
                    },
                }}
                speed={2000}
                className='mobileSwiper'
                style={{width: '100%'}}
            >
                <SwiperSlide><img src={mimg1} alt="NFT Whitelist Details" style={{width: '100%', maxWidth:'1200px'}} /></SwiperSlide>
                <SwiperSlide><img src={mimg2} alt="PFP NFT Drop Details" style={{width: '100%', maxWidth:'1200px'}} /></SwiperSlide>
                <SwiperSlide><img src={mimg3} alt="Comic Book Page Details" style={{width: '100%', maxWidth:'1200px'}} /></SwiperSlide>
            </Swiper>            
            <Footer />
        </div>
    );
}

export default Home;
