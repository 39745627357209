import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import * as nearAPI from 'near-api-js';

import Countdown from "react-countdown";
import CardModalWL from '../CardModalWL';

import 'swiper/scss';
import "swiper/css/effect-fade";

import img1 from '../../../assets/images/WL/WL1HRMS.jpg'
import img2 from '../../../assets/images/WL/WL2HRMS.jpg'
import img3 from '../../../assets/images/WL/WL3HRMS.jpg'
import img4 from '../../../assets/images/WL/WL4HRMS.jpg'
import img5 from '../../../assets/images/WL/WL5HRMS.jpg'
import logo from '../../../assets/images/WL/logo.png'
import { ReactComponent as NearType } from '../../../assets/images/icon/near_type.svg'

import { useSelector } from 'react-redux';

const LiveAuctionWL = () => {

    const nearWallet = useSelector(state => state.near.wallet);
    const nftContract = useSelector(state => state.near.nftContract);
    const [modalShow, setModalShow] = useState(false);
    const [slideTitle, setSlideTitle] = useState('HRMS #1a Whitelist NFTs');
    const [qtySold, setQtySold] = useState('-');
    const [tokens, setTokens] = useState([]);
    const { utils } = nearAPI;

    useEffect(() => {
        if (nftContract) {
            nftContract.get_user_minted_quantity().then((qty) => setQtySold(qty));
            if (nearWallet?.isSignedIn()) {
                nftContract.nft_tokens_for_owner({account_id: nearWallet.getAccountId()}).then((t) => setTokens(t));
            }
        }
    }, [nftContract, nearWallet]);

    const handlePurchase = async () => {
        const result = await nftContract.nft_mint(
            {},
            "75000000000000",
            utils.format.parseNearAmount("5") 
        );

    }

    return (
        <Fragment>
            <section className="tf-section live-auctions">
                { tokens && tokens.length > 0 
                  && tokens.map((nft, ind) => {
                      return <div key={'nft'+ind} className="themesflat-container">
                        {
                            /*
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="heading-live-auctions">
                                    <h2 className="tf-title" style={{padding:0}}>{nft.metadata.title}</h2>   
                                    <span style={{fontSize:16}}>#{nft.token_id} of 500</span>                             
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                            */
                        }

                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="swiper-container show-shadow carousel auctions">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="slider-item">
                                                <div className="sc-card-product">
                                                    <div className="card-media">
                                                        
                                                            <img src={nft.metadata.media} alt={nft.metadata.title} />
                                                    </div>
                                                    <div className="card-title">
                                                        <h5><a>{nft.metadata.title}</a></h5>
                                                        
                                                    </div>
                                                    <div className="meta-info">
                                                        <div className="author">
                                                            <div className="info">
                                                                <span>Owner</span>
                                                                <h6><a to="/authors-02">{nft.owner_id}</a> </h6>
                                                            </div>
                                                        </div>
                                                        <div className="price" style={{}}>                                                                        
                                                            <h5 style={{fontSize:20, display:'flex', alignItems: 'center', marginRight: 10}}>
                                                                #{ nft.token_id } of 500
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="col-md-2"></div>
                        </div>

                      </div>
                  })
                }
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="heading-live-auctions">
                                <h2 className="tf-title" style={{padding:0}}>Whitelist HRMS</h2>   
                                <span style={{fontSize:16}}>{300-qtySold} of 500 available</span>                             
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">                            

                            <div className="swiper-container show-shadow carousel auctions">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="slider-item">
                                            <div className="sc-card-product">

                                                { 
                                                    qtySold != 300 &&
                                                    <div className="button-place-bid" style={{zIndex: 22}}>
                                                        <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Mint Random NFT</span></button>
                                                    </div>
                                                }

                                                <div className="card-media">
                                                    
                                                        <Swiper
                                                            spaceBetween={10}
                                                            effect={"fade"}
                                                            modules={[EffectFade, Autoplay]}              
                                                            loop
                                                            autoplay={{
                                                                delay: 1,
                                                                disableOnInteraction: false,
                                                                pauseOnMouseEnter: false
                                                            }}
                                                            speed= {3000}
                                                            style={{borderRadius: '5px'}}
                                                            onSlideChangeTransitionStart={(e) => {
                                                                switch(e.realIndex) {
                                                                    case 0: setSlideTitle('HRMS #1a Whitelist NFTs'); break;
                                                                    case 1: setSlideTitle('HRMS #1b Whitelist NFTs'); break;
                                                                    case 2: setSlideTitle('HRMS #1c Whitelist NFTs'); break;
                                                                    case 3: setSlideTitle('HRMS #1d Whitelist NFTs'); break;
                                                                    case 4: setSlideTitle('HRMS #1e Whitelist NFTs'); break;
                                                                }                                                                
                                                            }}
                                                        >
                                                            <SwiperSlide><img src={img1} alt="HRMS #1a Whitelist NFTs" /></SwiperSlide>
                                                            <SwiperSlide><img src={img2} alt="HRMS #1b Whitelist NFTs" /></SwiperSlide>
                                                            <SwiperSlide><img src={img3} alt="HRMS #1c Whitelist NFTs" /></SwiperSlide>
                                                            <SwiperSlide><img src={img4} alt="HRMS #1d Whitelist NFTs" /></SwiperSlide>
                                                            <SwiperSlide><img src={img5} alt="HRMS #1e Whitelist NFTs" /></SwiperSlide>                        
                                                        </Swiper>
                                                        { 
                                                            qtySold != 300 &&
                                                            <div className="button-place-bid" style={{zIndex: 22}}>
                                                                <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Mint Random NFT</span></button>
                                                            </div>
                                                        }
                                                </div>

                                                { 
                                                    qtySold != 300 &&
                                                    <div className="button-place-bid" style={{zIndex: 22}}>
                                                        <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Mint Random NFT</span></button>
                                                    </div>
                                                }

                                                <div className="card-title">
                                                    <h5><a>{slideTitle}</a></h5>
                                                    
                                                </div>
                                                <div className="meta-info">
                                                    <div className="author">
                                                        <div className="avatar" style={{borderRadius:0}}>
                                                            <img src={logo} alt="Axies" />
                                                        </div>
                                                        <div className="info">
                                                            <span>Creator</span>
                                                            <h6><a to="/authors-02">Nearhub Comics</a> </h6>
                                                        </div>
                                                    </div>
                                                    <div className="price" style={{}}>                                                                        
                                                        <h5 style={{fontSize:40, display:'flex', alignItems: 'center', marginTop: -36, marginRight: 10}}>
                                                            { 
                                                                qtySold == 300 
                                                                ? 'SOLD OUT'
                                                                : <>5 <NearType style={{display:'inline', fill: '#fff', height: 15, marginLeft: 10}} /></>
                                                            }
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>                
            </section>
            <CardModalWL
                show={modalShow}
                onHide={() => setModalShow(false)}
                onPurchase={handlePurchase}                
            />
        </Fragment>
    );
}


export default LiveAuctionWL;
