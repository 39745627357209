import React from 'react';
import { Autoplay, EffectCoverflow, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import "swiper/css/effect-fade";
import "swiper/css/effect-coverflow";
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import img1 from '../../assets/images/WL/WL1HRMS.jpg'
import img2 from '../../assets/images/WL/WL2HRMS.jpg'
import img3 from '../../assets/images/WL/WL3HRMS.jpg'
import img4 from '../../assets/images/WL/WL4HRMS.jpg'
import img5 from '../../assets/images/WL/WL5HRMS.jpg'

const SliderStyle2 = () => {
    const subtitle = 'NFT MARKETPLACE'
    const title = 'Discover, find and sell extraordinary monster NFTs'
    const description = 'Marketplace for monster character cllections non fungible token NFTs'
    return (
        <section className="flat-title-page home5" style={{height:'104px'}}>
            <div className="overlay"></div>
            <div className="themesflat-container" style={{padding:0}}>
                <div className="wrap-heading flat-slider d-flex align-items-center" style={{padding:0}}>
                    {/*
                    <Swiper
                        spaceBetween={10}
                        effect={"fade"}
                        modules={[EffectFade, Autoplay]}
                        className="mobileSwiper"                        
                        loop
                        autoplay={{
                            delay: 1,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: false
                        }}
                        speed= {3000}
                        style={{borderRadius: '5px'}}
                    >
                        <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img4} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img5} alt="Axies" /></SwiperSlide>                        
                    </Swiper>
                    */}
                    <Swiper
                        modules={[ Autoplay ]}
                        direction={"vertical"}
                        spaceBetween={10}
                        slidesPerView={5}
                        loop
                        autoplay={{
                            delay: 1,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                            reverseDirection: true
                        }}
                        speed= {2000}
                        className=""      
                    >
                        <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img4} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img5} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img4} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img5} alt="Axies" /></SwiperSlide>

                    </Swiper>
                    <Swiper
                        modules={[ Autoplay ]}
                        direction={"vertical"}
                        spaceBetween={10}
                        slidesPerView={5}
                        loop
                        autoplay={{
                            delay: 1,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                            reverseDirection: true
                        }}
                        speed= {2100}
                        className=""      
                    >
                        <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img4} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img5} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img4} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img5} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>

                    </Swiper>
                    <Swiper
                        modules={[ Autoplay ]}
                        direction={"vertical"}
                        spaceBetween={10}
                        slidesPerView={5}
                        loop
                        autoplay={{
                            delay: 1,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                            reverseDirection: true
                        }}
                        speed= {2200}
                        className=""      
                    >
                        <SwiperSlide><img src={img4} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img5} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img4} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img5} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>

                    </Swiper>
                    <Swiper
                        modules={[ Autoplay ]}
                        direction={"vertical"}
                        spaceBetween={10}
                        slidesPerView={5}
                        loop
                        autoplay={{
                            delay: 1,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                            reverseDirection: true
                        }}
                        speed= {2000}
                        className='end '
                    >
                        <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                        <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>

                    </Swiper>                         
                </div>
            </div>                           
        </section>
    );
}



export default SliderStyle2;
