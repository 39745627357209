const NEAR = {
    NETWORK: process.env.NEAR_NETWORK || 'mainnet',
    NFT_CONTRACT_NAME: process.env.NFT_CONTRACT_NAME || 'nft-random.nearhubonline.near',
    NFT2_CONTRACT_NAME: process.env.NFT2_CONTRACT_NAME || 'tmmp.nearhubonline.near',
    NFT3_CONTRACT_NAME: process.env.NFT3_CONTRACT_NAME || 'hrms.nearhubonline.near',
    MARKET_CONTRACT_NAME: process.env.MARKET_CONTRACT_NAME || 'auction.nearhubonline.testnet',
    ROYALITY_ACCOUNT: 'nearhubonline',
    ROYALITY_PERCENTAGE: 0.5,
};

export default NEAR;